<template>
	<div>
		<div class="text-right">
			<router-link
				:to="{ name: 'BundleSetCreate' }"
				class="btn btn-secondary mb-3"
			>
				<CIcon class="mr-2" name="cil-plus" />
				Create bundle set
			</router-link>
		</div>

		<!-- TODO: remove `v-if="false"` when has to use search and filter -->
		<div v-if="false" class="d-flex align-items-start">
			<form
				class="search-form flex-fill"
				@submit.prevent="handleSearch"
			>
				<CInput
					v-model.trim="queryParams.q"
					data-test-id="search-input"
					placeholder="Search by coupon code, promotion name, and ID "
				>
					<template #prepend-content>
						<CIcon
							name="cil-magnifying-glass"
							data-test-id="search-button"
						/>
					</template>
				</CInput>
			</form>
			<BaseFilterButton
				class="ml-3"
				data-test-id="filter-button"
				:is-open="isShowFilter"
				:has-filter="hasFilter"
				@onClick="toggleFilter"
			/>
		</div>

		<!-- TODO: remove `v-if="false"` when has to use search and filter -->
		<div v-if="false">
			<transition name="fade">
				<div
					v-if="isShowFilter"
					class="filter-box rounded-sm pt-3 px-3 mb-3"
				>
					<CRow>
						<CCol md="3">
							<BaseInputDate
								:value="dateRange"
								label="Period date"
								placeholder="All periods"
								mode="range"
								@input="handleFilterDateChange"
							>
								<template #append-content>
									<CIcon
										class="cil-calendar"
										name="cil-calendar"
									/>
								</template>
							</BaseInputDate>
						</CCol>
						<CCol md="3">
							<BaseDropDown
								v-model="filter.period"
								:searchable="false"
								:options="BUNDLE_SET_PERIODS_OPTIONS"
								:allow-empty="false"
								class="select-custom"
								label="name"
								track-by="value"
								label-drop-down="Period status"
								@input="handleBundleSetPeriod"
							/>
						</CCol>
					</CRow>
				</div>
			</transition>
		</div>

		<!-- TODO: remove `v-if="false"` when has to use search and filter -->
		<div v-if="false" class="mb-4">
			<BaseSearchFormFooter
				:count="list.meta.total"
				append-text="list(s) found"
				@onReset="resetFilter"
			/>
		</div>

		<div>
			<BaseTable
				:is-loading="isLoading"
				:pagination="{
					pages: list.meta.lastPage,
					activePage: list.meta.currentPage
				}"
				:fields="BUNDLE_SET_TABLE_FIELDS"
				:items="list.data"
				clickable-rows
				striped
				:link-to="ROUTE_NAME.BUNDLE_SET_EDIT"
				vertical-align="top"
				class="table-custom"
				@onPaginationClick="handlePageChange"
			>
				<template #no-items-view>
					<div class="empty-table-element">
						<p class="subtitle">
							{{
								$t("global.searchNotFound", {
									field: "bundle set"
								})
							}}
						</p>
					</div>
				</template>
				<template #status="{item}">
					<CBadge
						class="badge-status"
						:color="BUNDLE_SET_COLOR.STATUS[item]"
					>
						{{ BUNDLE_SET_TITLE.STATUS[item] }}
					</CBadge>
				</template>
				<template #period="{item}">
					<CBadge class="badge-status" :color="item.color">
						{{ item.title }}
					</CBadge>
				</template>
				<template #duplicateId="{item}">
					<CLink
						variant="ghost"
						color="secondary"
						:to="{ name: 'BundleSetCreate', query: { ref: item } }"
					>
						Duplicate
					</CLink>
				</template>
			</BaseTable>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
	BUNDLE_SET_TABLE_FIELDS,
	BUNDLE_SET_PERIODS_OPTIONS,
	BUNDLE_SET_TITLE,
	BUNDLE_SET_COLOR,
} from '../enums/bundleSet';
import {
	cleanObject,
	randomSearchString,
	hasSearchFilter,
	convertDateRangeToUTC,
	cloneDeep,
} from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'BundleSetList',
	data() {
		return {
			BUNDLE_SET_TABLE_FIELDS,
			BUNDLE_SET_PERIODS_OPTIONS,
			BUNDLE_SET_TITLE,
			BUNDLE_SET_COLOR,
			ROUTE_NAME,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				period_status: this.$route.query.period_status || null,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				r: randomSearchString(),
			},
			filter: {
				period: this.getSelectedValue(
					this.$route.query.period_status,
					BUNDLE_SET_PERIODS_OPTIONS,
				),
			},
			isShowFilter: false,
		};
	},
	computed: {
		...mapState('bundleSet', {
			list: 'list',
		}),
		isLoading() {
			return this.list.isLoading;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		startDate() {
			if (!this.queryParams.start_at) {
				return null;
			}

			return new Date(this.queryParams.start_at);
		},
		endDate() {
			if (!this.queryParams.end_at) {
				return null;
			}

			return new Date(this.queryParams.end_at);
		},
		dateRange() {
			if (!this.startDate || !this.endDate) {
				return null;
			}

			return {
				start: this.startDate,
				end: this.endDate,
			};
		},
	},
	async created() {
		this.isShowFilter = this.hasFilter;
		await this.getBundleSets(this.queryParams);
	},
	methods: {
		...mapActions({
			getBundleSets: 'bundleSet/getBundleSets',
		}),

		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},

		getSelectedValue(selectedValue = null, options = {}) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return (
				statusOptionValues.find(findStatus) || {
					name: null,
					value: null,
				}
			);
		},

		handleFilterDateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.start_at = start;
			this.queryParams.end_at = end;
			this.updateUrlParams();
		},

		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query });
		},

		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},

		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},

		handleBundleSetPeriod(event) {
			this.queryParams.page = null;
			this.queryParams.period_status = event.value;
			this.updateUrlParams();
		},

		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				period_status: null,
				start_at: null,
				end_at: null,
				r: null,
			};

			this.updateUrlParams();
		},
	},
};
</script>

<style lang="scss" scoped>
// Set placeholder of filter by date range
::v-deep .form-control-date {
	input[type="input"] {
		@include placeholder-black;
	}
}

.badge-status {
	min-width: rem(65);
}

.filter-box {
	background-color: $color-gray-100;
}
</style>
